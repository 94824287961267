@keyframes player-grow {
  from {
    transform: scale(1);
    opacity: .8;
  }
  to {
    transform: scale(1.3);
    opacity: 0;
  }
}
